import { Footer } from "../components/layout/Footer";
import { Header } from "../components/layout/Header";
import { CartComponent } from "../components/cart";


export const CartPage = () => {
    return (
        <>
        <Header/>
        <CartComponent/>
        <Footer/>
        </>
        
    )
  
};
