import styled from "styled-components";
import { useEffect, useState } from "react";
import { TextLink } from "../generic/TextLink";
import { Container, Img, Section, Row, Col } from "../../style";
import { Colors } from "../../theme/colors";
import { Brand, Icons } from "../../constants/images";
import { LinksList } from "../generic/LinksList";
import { useDeviceType } from "../../context/DeviceContext";
import { MenuLinks } from "../generic/menuLinks";
import {NavLink} from "react-router-dom"
import { MetaTags } from "./MetaTags";
import { useCustomer } from "../../context/CustomerContext";
import { MemeberMenu } from "./MemberMenu";

const Links = [
  {
    text: "HOME",
    to: "/"
  },
  {
    text: "SHOP",
    to: "/shop"
  },
  
  {
    text: "ROYALTY POINTS",
    to: "/royalty"
  },
  {
    text: "GUIDES",
    to: "/guides"
  },
  {
    text: "CONTACT",
    to: "/contact"
  },
];

const BurgerMenu = styled.div`
  width: 30px;
  height: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  & div {
    width: 100%;
    height: 4px;
    background-color: ${Colors.blackText};
  }
`;

const MobileMenu = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${Colors.lightPrimary};
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
`;

const CloseButton = styled.div`
  align-self: flex-end;
  font-size: 24px;
  cursor: pointer;
`;

export const Header = () => {
  const { isMobile } = useDeviceType();
  const [menuOpen, setMenuOpen] = useState(false);
  const {customer} = useCustomer()
  const [showMemberMenu,setShowMemberMenu] = useState<boolean>(false)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
    <MetaTags/>
    <Section $backgroundColor1={Colors.whiteBackground} padding="0">
      <Container padding="10px 0px">
        
          {!isMobile && 
          <Row justify="space-between">
          <Col $maxWidth="300px" className="logo">
            <NavLink to="/">
              <Img src={Brand.logo} width={isMobile ? 150 : 300} margin="0" />
            </NavLink>
          </Col>
          <Col margin="20px 0px 0px 0px">
           <LinksList links={Links} fontSize="16px" fontColor={Colors.blackText} />
         </Col>
         <Col  margin="20px 0px 0px 0px" $maxWidth="250px">
         <Row gap="10px">
         
          <TextLink fontSize="16px" fontColor={Colors.blackText} route="/cart">
            CART
          </TextLink>
          <MemeberMenu visibile={showMemberMenu} clicked={()=>setShowMemberMenu(false)}/>
          <NavLink to="/cart">
              <Img src={Icons.Cart} width={"25px"} margin="-5px 0px 0px 0px" />
          </NavLink>
          {
                customer &&
                <NavLink onClick={()=>{setShowMemberMenu((prev)=>!prev)}} to="#">
                <Img src={Icons.Member} width={"25px"} margin="0px 0px 0px 0px" />
               </NavLink>
              }
               {
                !customer &&
              <NavLink to="/login">
                <Img src={Icons.Login} width={"25px"} margin="-3px 0px 0px 0px" />
              </NavLink>
              }
        </Row>
         </Col>
       
         </Row>
          }

          {
            isMobile &&
            <Row justify="space-around">
              <Col $maxWidth="50px">
              <BurgerMenu onClick={toggleMenu}>
                  <div />
                  <div />
                  <div />
                </BurgerMenu>
              </Col>
              <Col className="logo" margin="3px 0px 0px 0px">
                <NavLink to="/">
                  <Img src={Brand.logo} width={isMobile ? 150 : 350} margin="0" />
                </NavLink>
              </Col>
              <Col $maxWidth="80px" margin="20px 0px 0px 0px">
                <Row gap="10px">
                <MemeberMenu visibile={showMemberMenu} clicked={()=>setShowMemberMenu(false)}/>
                  <NavLink to="/cart">
                      <Img src={Icons.Cart} width={"25px"} margin="-10px 0px 0px 0px" />
                  </NavLink>
                  {
                        customer &&
                        <NavLink onClick={()=>{setShowMemberMenu((prev)=>!prev)}} to="#">
                        <Img src={Icons.Member} width={"25px"} margin="-6px 0px 0px 0px" />
                      </NavLink>
                      }
                      {
                        !customer &&
                      <NavLink to="/login">
                        <Img src={Icons.Login} width={"25px"} margin="-8px 0px 0px 0px" />
                      </NavLink>
                      }
                </Row>
              </Col>

            </Row>

          }

          
          {/* <Col $maxWidth="350px" className="logo">
            <NavLink to="/">
              <Img src={Brand.logo} width={isMobile ? 150 : 350} margin="0" />
            </NavLink>
          </Col>
          {!isMobile && (
            <Col margin="20px 0px 0px 0px">
              <LinksList links={Links} fontSize="16px" fontColor={Colors.blackText} />
            </Col>
          )}
          <Col margin="20px 0px 0px 0px" $maxWidth="200px">
            <Row justify="flex-end" margin="0px 15px 0px 0px">
              {!isMobile && (
                <TextLink fontSize="16px" fontColor={Colors.blackText} route="/cart">
                  CART
                </TextLink>
              )}
              <Col padding="0" margin="0" $maxWidth="60px">
                <MemeberMenu visibile={showMemberMenu} clicked={()=>setShowMemberMenu(false)}/>
                <NavLink to="/cart">
                  <Img src={Icons.Cart} width={"25px"} margin="-5px 0px 0px 0px" />
                </NavLink>
              </Col>
              {
                customer &&
                <NavLink onClick={()=>{setShowMemberMenu((prev)=>!prev)}} to="#">
                <Img src={Icons.Member} width={"25px"} margin="0px 0px 0px 0px" />
               </NavLink>
              }
               {
                !customer &&
              <NavLink to="/login">
                <Img src={Icons.Login} width={"25px"} margin="-3px 0px 0px 0px" />
              </NavLink>
              }
             
              {isMobile && (
                <BurgerMenu onClick={toggleMenu}>
                  <div />
                  <div />
                  <div />
                </BurgerMenu>
              )}
            </Row>
          </Col> */}
        
      </Container>
      {menuOpen && (
        <MobileMenu>
          <CloseButton onClick={toggleMenu}>X</CloseButton>
          <MenuLinks closeMenu={toggleMenu} links={Links} fontSize="16px" fontColor={Colors.blackText} />
        </MobileMenu>
      )}
    </Section>
    </>
    
  );
};
