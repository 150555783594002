import { useEffect } from "react";
import styled from "styled-components";
import { BaseLayout } from "../components/layout/BaseLayout";
import { useLocation } from "react-router-dom";
import { useDeviceType } from "../context/DeviceContext";
import { Shop } from "../components/shop";


export const ShopPage = () => {

    const location = useLocation()
    const { isMobile } = useDeviceType()

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);


    return (
        <BaseLayout>
        <ContentArea $isMobile={isMobile}>
            <Shop/>
        </ContentArea>
            
        </BaseLayout>
    )




}


const ContentArea = styled.div<{$isMobile:boolean}>`
    margin-left: ${props=>props.$isMobile?"":"220px"};
    margin-right: ${props=>props.$isMobile?"":"220px"};
`