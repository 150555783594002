import * as React from "react";

import { useDeviceType } from "../../context/DeviceContext";
import {  RequestConfigs } from "./../../api";
import { DataLayoutContainer,Head ,DataContainer} from "./index.style";
import { useFormHandling } from "../../hooks/FormHandle";
import { ItemPreview } from "./itemPreview";
import { WrappedRow } from "../../style";





export const Populars = () => {

  
  const {isMobile} = useDeviceType()
    const {  handleClick, serverRes} = useFormHandling({ requestType: RequestConfigs.getShop,params:{campaignId:process.env.REACT_APP_CAMPAIGN_ID} })


  
  React.useEffect(() => {
    
    handleClick()

  }, []);




  const data = serverRes?.shopItems?.sort((a, b) => a.popularity - b.popularity).slice(0,3)


  

  return (
    
    <DataLayoutContainer>
      
    

     
      <Head>
      
      <DataContainer id="data-container" $isMobile={isMobile}>
      <WrappedRow justify="center" id="body-container">
        <>
        {
        data && data.map((item,index) => (
          <ItemPreview shopItem={item}  key={index}/>

        ))}

        </>
        
      </WrappedRow>
  
  </DataContainer>  
        </Head>
    
    </DataLayoutContainer>
  );
};

