import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormHandling } from "../hooks/FormHandle";
import { RequestConfigs } from "../api";
import { useCampaign } from "../context/CampaignContext";
import { useClient } from "../context/ClientContext";
import { useCart } from "../context/CartContext";


export const AutoCartPage = () => {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const campaignId = queryParams.get('campaignId');
    const itemId = queryParams.get('itemId');
    const orderId = queryParams.get('orderId');
    const affId = queryParams.get('affId') || "";
    const cartPath = queryParams.get('couponCode')?`?couponCode=${queryParams.get('couponCode')}&affId=${affId}`:'?affId=${affId}'
    const navigate = useNavigate()
    const campaignReq = useFormHandling({requestType:RequestConfigs.getCampaign,data:{campaignId:campaignId}})
    const orderReq = useFormHandling({requestType:RequestConfigs.getOrder,params:{orderId}})

    useEffect(() => {
        if(campaignId && itemId){
            campaignReq.handleClick()
            if(orderId){
                orderReq.handleClick()
            }
        } else {
            navigate("/")
        }
      }, []);

    
      
      const {campaign,setCampaign} = useCampaign()
      const {setClient} = useClient()
      const {addToCart,emptyCart} = useCart()

      const getCartItemId = (campaign:Campaign)=>{
        const item = campaign.products.find((product)=>product.campaignProductId===Number(itemId))
        if(item)
            return item.campaignProductId
        else
            return campaign.products[0].campaignProductId

      }
      
      useEffect(() => {
        console.log("there")
            if(orderId){
                if(orderReq.serverRes && campaignReq.serverRes?.campaign && campaignReq.serverRes.client){
                    const passData:any = {}
                    campaignReq.serverRes.campaign.products.sort((a, b) => a.price - b.price)
                    setCampaign(campaignReq.serverRes.campaign)
                    setClient(campaignReq.serverRes.client)
                    const matchId = getCartItemId(campaignReq.serverRes.campaign)
                    emptyCart()
                    addToCart(matchId)
                    if(orderReq.serverRes.order){
                        const order = orderReq.serverRes.order
                        passData.state = {
                            firstName:order.firstName,
                            lastName:order.lastName,
                            emailAddress:order.emailAddress,
                            phoneNumber:order.phoneNumber,
                            state:order.state,
                            city:order.city,
                            address1:order.address1,
                            postalCode:order.postalCode,
                        }
                    }
                    navigate("/cart"+cartPath,passData)
                 } else if(campaignReq.serverRes && orderReq.serverRes) {
                    navigate("/")
                 }

            } else {
                console.log("here")
                if(campaignReq.serverRes?.campaign && campaignReq.serverRes.client){
                    campaignReq.serverRes.campaign.products.sort((a, b) => a.price - b.price)
                    setCampaign(campaignReq.serverRes.campaign)
                    setClient(campaignReq.serverRes.client)
                    const matchId = getCartItemId(campaignReq.serverRes.campaign)
                    emptyCart()
                    addToCart(matchId)
                    navigate("/cart"+cartPath)
                 } else if(campaignReq.serverRes) {
                    navigate("/")
                 }

            }
        
        }, [campaignReq.serverRes,orderReq.serverRes]);
    return (
        <>
       
        </>
        
    )
  
};
