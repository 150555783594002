import styled from "styled-components";
import { Colors } from "../../theme/colors";
import { NavLink } from "react-router-dom";
import { usePopup } from "../../context/PopUpContext";

interface MemeberMenuProps {
    visibile:boolean
    clicked:()=>void
}
export const MemeberMenu = ({visibile,clicked}:MemeberMenuProps)=>{

    const {openPopup} = usePopup()

    const handleClick = ()=>{
        openPopup("/logout")
        clicked()
    }

    if(!visibile)
        return null
    return (
        <Col>
              <Nav onClick={handleClick} to="#">
                <SegoeP $fontSize="18px" weight={600}>Logout</SegoeP>
              </Nav>
            
        </Col>
    )
}


const Nav = styled(NavLink)`
 text-decoration: none;
 padding: 10px;
 color:${Colors.blackText};
 &:hover {
    
    color:${Colors.primary};
  }
`
const Col = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  z-index: 5;
  top:30px;
  right: 0px;
  border-radius: 5px;
  color: cadetblue;
  background-color:${Colors.whiteBackground};
  border:1px solid ${Colors.fieldBorder};
  width: 220px;
  
`;

const SegoeP = styled.p<{ margin?:string, color?: string, $fontSize?: string,$lineHeight?: string, weight?: number, direction?: 'center' | 'end' | 'start' }>`
font-size: ${props => props.$fontSize ? props.$fontSize : '16px'};
margin: ${props => props.margin ||  '4px 0px'};
line-height: ${props => props.$lineHeight ? props.$lineHeight : "22px"};
font-weight: ${props => props.weight ? props.weight : 400};
text-align: ${props => props.direction || 'start'};
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
`