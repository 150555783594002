
import { useEffect } from "react"
import { RequestConfigs } from "../api"
import { useCustomer } from "../context/CustomerContext"
import { useFormHandling } from "../hooks/FormHandle"
import { Btn, Col, Row, SegoeP } from "../style"
import { Colors } from "../theme/colors"
import { usePopup } from "../context/PopUpContext"
import { useNavigate } from "react-router-dom"


export const LogoutPage = () =>{

    const {handleClick,serverRes} = useFormHandling({requestType:RequestConfigs.logout})
    const {setCustomer} = useCustomer()
    const {closePopup} = usePopup()
    const navigate = useNavigate()


    const handleLogout = ()=>{
        handleClick()
    }

    useEffect(() => {
        if(serverRes?.succeeded){
            console.log("succeed logout")
            setCustomer(null)
            navigate("/")
            closePopup()
        }
      }, [serverRes]);
    return (
        <Col>
            <SegoeP>Are you sure you wish to Logout?</SegoeP>
            <Row gap="10px" margin="15px 0px 0px 0px">
                <Btn width="70px" onClick={handleLogout} to={"#"}>Yes</Btn>
                <Btn  $backgroundColor1={Colors.secondary} width="70px" onClick={closePopup} to={"#"}>No</Btn>
            </Row>
        </Col>
        
    )
    
}