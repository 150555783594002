import { useEffect } from "react";
import { Col, Container, Title, Text, Btn, Row } from "../style";
import { Backgrounds } from "../constants/images";
import styled from "styled-components";
import { Colors } from "../theme/colors";
import { BaseLayout } from "../components/layout/BaseLayout";
import { useLocation } from "react-router-dom";
import { useDeviceType } from "../context/DeviceContext";
import { Products } from "../components/home/products";
import { useCustomer } from "../context/CustomerContext";
import { GuideList } from "../components/guides/GuidesList";


export const GuidesPage = () => {

    const location = useLocation()
    const { customer } = useCustomer()
    const { isMobile } = useDeviceType()

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);


    return (
        <>
            <BaseLayout>

                <Section $isMobile={isMobile}>
                    <Container>
                        <Row>
                            <Col align="start">
                                <Col justify="center" align="start" $maxWidth="600px" gap="10px">

                                    <Title $isMobile={isMobile} $fontSize={54} $lineHeight={64} color={Colors.blackText}>
                                        Guides for Wellness
                                    </Title>
                                    <Col $maxWidth="450px" margin="0px 0px 20px 0px">
                                        <Text weight={600} color={Colors.blackText}>
                                        Unlock a world of health and wellness resources tailored just for you. Our expertly crafted guides on nutrition, fitness, and sustainable living are available exclusively for SlimmyGummy members
                                        </Text>
                                    </Col>

                                    {
                                        !customer &&
                                            
                                            <Row justify="flex-start">
                                            <Col align="start">
                                                <Btn width="240px" to={"/login"}>Login</Btn>
                                                <Col align="start">
                                                    <Text weight={500} color={Colors.whiteBackground}>Login to access your guides </Text>
                                                </Col>
                                            </Col>
                                            </Row>
                                    }

                                            

                                        </Col>

                            </Col>
                        </Row>

                    </Container>

                </Section>
                {
                    customer &&
                    <GuideList />
                }

                <Products />


            </BaseLayout>

        </>

    )

};


const Section = styled.div<{ $isMobile?: boolean }>`
  position: relative;
  width: 100%;
  height: 668px;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:${props=>props.$isMobile?'rgba(255, 255, 255, 0.6)':'none'};
    background-image:${props=>props.$isMobile?`url(${Backgrounds.GuidesBgM})`:`url(${Backgrounds.GuidesBg})`} ;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-blend-mode: overlay; /* This blends the color and image */
    
    z-index: -1; /* Ensures background is behind the content */
  }
`;