import styled from "styled-components";
import { Btn, Col,Row, SegoeP, Title, WrappedRow } from "../../style";
import { Colors } from "../../theme/colors";
import { useDeviceType } from "../../context/DeviceContext";

interface GuideProps {
    title:string;
    description:string;
    imagePreview:string;
    downloadLink?:string;
    purchasedOn:Date | null;
    avaliablityDays:number;
    
}
export const Guide = ({title,description,imagePreview,downloadLink="#",purchasedOn,avaliablityDays}:GuideProps)=>{
    const {isMobile} = useDeviceType()


    const isDateBeforeThreshold = (date:Date, numberOfDays:number) => {
        const givenDate = new Date(date);
        const currentDate = new Date();
        const thresholdDate = new Date();
        thresholdDate.setDate(currentDate.getDate() - numberOfDays);

        return givenDate < thresholdDate;
    }

    const missingDays = ()=>{
        const currentDate = new Date()
        if(purchasedOn){
            const targetDate  =new Date(purchasedOn.setDate(purchasedOn.getDate() + avaliablityDays))
            const differenceInTime  = targetDate.valueOf()-currentDate.valueOf()
            return Math.ceil(differenceInTime / (1000 * 60 * 60 * 24)); // Round up to avoid fractions
        }
        return 0        
    }

    
    let avaliableStatus = 'Not Avaliable'
    let color = Colors.error
    if(purchasedOn && isDateBeforeThreshold(purchasedOn,avaliablityDays )){
        avaliableStatus = 'Avaliable'
        color=Colors.primary
    } else if(purchasedOn){
        avaliableStatus = 'Pending'
        color=Colors.secondary
    }

    const errorText = avaliableStatus==='Not Avaliable'?'Only Customers with subscription can watch this content':`This content will be avaliable when you will have ${avaliablityDays} days of Active subscription -  ${missingDays()} to go`

    return (

        <Box className="box">
            <WrappedRow justify="flex-start">
                <Col $maxWidth={isMobile?"100%":"15%"}>
                    <EbookPreview  $isMobile={isMobile} src={process.env.REACT_APP_API_ENDPOINT+imagePreview} />
                </Col>
                <Col align="start" justify="flex-start" gap="10px" $maxWidth={isMobile?"100%":"80%"}>
                    <WrappedRow justify="space-between">
                        <Col align="start"><SegoeP weight={700}  $fontSize={"20px"}>{title}</SegoeP></Col>
                        
                        <Row justify="flex-start" gap="10px">
                            <SegoeP color={Colors.blackText} weight={600} $fontSize="16px">Content Status:</SegoeP>
                            <AvailiableBox $color={color}>
                                <SegoeP color={Colors.whiteBackground} weight={700} $fontSize="16px">{avaliableStatus}</SegoeP>
                            </AvailiableBox>
                        </Row>
                        
                    </WrappedRow>
                    
                    <SegoeP $fontSize="16px">{description}</SegoeP>
                    {
                        avaliableStatus==='Avaliable' &&
                        <Btn $backgroundColor1={Colors.secondary} title="Watch" to={process.env.REACT_APP_API_ENDPOINT+downloadLink} width="100px">Read</Btn>
                    }
                    {
                        avaliableStatus!=='Avaliable' &&
                        <SegoeP color={color} weight={700} $fontSize="16px">{errorText}</SegoeP>
                        
                    }
                    
                    
                    
                </Col>
            </WrappedRow>
            
        </Box>
    )
}

const Box = styled.div<{ $background?:string,gap?:string, margin?:string}>`
display: flex;
flex-direction: column;
background: ${props=>props.$background || 'transparent'};
width: 100%;
box-sizing: border-box;
padding: 11px;
gap:${props => props.gap || '10px'};
margin: ${props => props.margin || '0'};
border:solid 1px ${Colors.fieldBorder};
border-radius: 10px;
`

const EbookPreview = styled.img<{ $isMobile: boolean }>`
box-shadow: 0 0 23px  ${Colors.shadow};
/* border-radius: 50% */
border-radius: 8px;
width: ${(prop => prop.$isMobile ? '100px' : '110px')};
/* height: ${(prop => prop.$isMobile ? '120px' : '140px')}; */
object-fit: cover;
margin:0;
transition: transform 0.5s ease-in-out;
&:hover {
    transform: scale(1.05); /* Enlarge the image on hover */
    cursor: pointer;
  }
`

const AvailiableBox = styled.div<{$color:string}>`
display: flex;
border-radius: 10px;
background-color:${props=>props.$color};
justify-content: center;
align-items: center;
padding: 5px;
border:solid 1px ${Colors.fieldBorder};
width: 125px;

`
