import { useEffect, useState } from "react"
import { RequestConfigs } from "../../api"
import { useFormHandling } from "../../hooks/FormHandle"
import { Container } from "../../style"
import { Guide } from "./Guide"


export const GuideList = ()=>{

    const {  handleClick,  serverRes } = useFormHandling({ requestType: RequestConfigs.getGuides })
    const [guides,setGuides] = useState<Guide[]>([])
    const [purchasedOn,setPurchasedOn] = useState<Date | null>(null)

    useEffect(() => {
        handleClick()
    }, []);


    useEffect(() => {
        if (serverRes?.succeeded && serverRes?.guides) {
          
            setGuides(serverRes?.guides.guides)
            setPurchasedOn(serverRes?.guides.purchasedOn)
        }
        
    }, [serverRes]);

    return (
        <Container $gap="25px" padding="50px 0px">
            { guides.length>0 &&
                guides.map((d,index)=>(
                    <Guide {...d} purchasedOn={purchasedOn} key={index}/>
                ))
            }
            

            
                 
        </Container>
    )
}