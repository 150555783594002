import './App.css';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import { DeviceProvider } from './context/DeviceContext';
import { LoaderProvider } from './context/LoaderContext';


import { HomePage } from './pages/home';

import { CampaignProvider } from './context/CampaignContext';
import { OrderProvider } from './context/OrderContext';
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4';
import { createContext, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { MarketingProvider } from './context/MarketingContext';
import { ClientProvider } from './context/ClientContext';
import { CartPage } from './pages/cart';
import { CartProvider } from './context/CartContext';
import { Ingredients } from './pages/ingredients';
import { Contact } from './pages/contact';
import { PopupProvider } from './context/PopUpContext';
import { ThankUPage } from './pages/ThankU';
import { TermsPage } from './pages/terms';
import { PrivacyPage } from './pages/privacy';
import { AutoCartPage } from './pages/autoCart';
import { LoginPage } from './pages/Login';
import { AutoLoginPage } from './pages/AutoLogin';
import { CustomerProvider } from './context/CustomerContext';
import { RoyaltyPage } from './pages/Royalty';
import { MantineProvider  } from '@mantine/core';
import { FilterProvider } from './context/FilterContext';
import { ShopPage } from './pages/Shop';
import { ProductDetailsPage } from './pages/ProductDetails';
import { LogoutPage } from './pages/Logout';
import { GuidesPage } from './pages/Guides';
import { PreviousPathProvider } from './context/PreviousPathContext';

ReactGA.initialize(process.env.REACT_APP_GA || "");

const tagManagerArgs = {
  gtmId: 'GTM-W27NSK5H'
}
TagManager.initialize(tagManagerArgs)

interface WrapperProps{
  children:React.ReactElement
}
const Wrapper:React.FC<WrapperProps> = ({children}) => {
  const location = useLocation();
  
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

 
 return children
}

function App() {
  
  window.dataLayer = window.dataLayer || [];
  useEffect(() => {
    ReactGA.send(
      {
        hitType: "pageview",
        page: window.location.pathname
      }
    )
  }, []);
  return (
    <>
 <DeviceProvider>
 
        <LoaderProvider>
          <PopupProvider>
          <ClientProvider>
            <CustomerProvider>
            <CampaignProvider>
            <CartProvider>
            <MarketingProvider>
            
              <OrderProvider>
                <FilterProvider>
                <MantineProvider >
                <BrowserRouter basename='/'>
                <PreviousPathProvider>
                <Wrapper>
                  
                    <Routes>
                      <Route path={"/"} element={<HomePage />} />
                      <Route path={"/login"} element={<LoginPage />} />
                      <Route path={"/auto-login"} element={<AutoLoginPage />} />
                      <Route path={"/royalty"} element={<RoyaltyPage />} />
                      <Route path={"/guides"} element={<GuidesPage />} />
                      <Route path={"/cart"} element={<CartPage />} />
                      <Route path={"/shop"} element={<ShopPage />} />
                      <Route path="/shop/:id" element={<ProductDetailsPage />} />
                      <Route path={"/auto-cart"} element={<AutoCartPage />} />
                      <Route path={"/ingredients"} element={<Ingredients />} />
                      <Route path={"/contact"} element={<Contact />} />
                      <Route path={"/thank-you"} element={<ThankUPage />} />
                      <Route path={"/us/terms"} element={<TermsPage />} />
                      <Route path={"/eu/terms"} element={<TermsPage />} />
                      <Route path={"/us/privacy"} element={<PrivacyPage />} />
                      <Route path={"/eu/privacy"} element={<PrivacyPage />} />
                      <Route path={"/logout"} element={<LogoutPage />} />

                      
                    </Routes>
                    
                  </Wrapper>
                  </PreviousPathProvider>
                  
                </BrowserRouter>
                </MantineProvider>
                </FilterProvider>
             
              </OrderProvider>
              

            </MarketingProvider>

            </CartProvider>
            

          </CampaignProvider>

            </CustomerProvider>
          

          </ClientProvider>

          </PopupProvider>
          
        </LoaderProvider>
        
      </DeviceProvider>
    
     
    </>
  );
}

export default App;
