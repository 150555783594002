import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

const PreviousPathContext = createContext<string | null>(null);

export const PreviousPathProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const [previousPath, setPreviousPath] = useState<string | null>(null);
  const prevLocation = useRef<string | null>(null);

  useEffect(() => {
    // Update the previous path only if the current location is different from the login path
    if (location.pathname !== '/login') {
      prevLocation.current = location.pathname; // Save the current path before it changes
    }
    setPreviousPath(prevLocation.current);
  }, [location]);

  return (
    <PreviousPathContext.Provider value={previousPath}>
      {children}
    </PreviousPathContext.Provider>
  );
};

export const usePreviousPath = () => useContext(PreviousPathContext);
