import { useEffect } from "react"
import { RequestConfigs } from "../../api"
import { useCampaign } from "../../context/CampaignContext"
import { useCart } from "../../context/CartContext"
import { useDeviceType } from "../../context/DeviceContext"
import { useFormHandling } from "../../hooks/FormHandle"
import { Container, WrappedRow,Col, Section, Title,Text, Btn } from "../../style"
import { Billing } from "./billing"
import { CartProducts } from "./cartProducts"



export const CartComponent = () => {

    const {cart} = useCart()
    const {isMobile} = useDeviceType()
    const {campaign,setCampaign} = useCampaign()
    const {serverRes,handleClick} = useFormHandling({requestType:RequestConfigs.getCampaign,data:{campaignId:campaign?.campaignId || process.env.REACT_APP_CAMPAIGN_ID}})

    useEffect(() => {
        if(!campaign){
            handleClick()
        }
      }, []);

      useEffect(() => {
        if(serverRes?.campaign){
            setCampaign(serverRes.campaign)
        }
      }, [serverRes]);

    return (
        <>
        <Section $backgroundColor1="white">
        <Container>
            
                { cart.length>0 && 
                <WrappedRow justify="space-between">
                    <Col $maxWidth="600px" justify="flex-start">
                    <CartProducts/>
                </Col>
                <Col $maxWidth="450px" justify="flex-start">
                    <Billing/>
                </Col>
                </WrappedRow>
                }

                { (!cart || cart.length===0) && 
                    <Col gap="20px" margin="50px 0px 400px 0px">
                        <Text>You have not selected any product...</Text>
                        <Btn to="/shop">Browse Products</Btn>
                    </Col>
                }               
                
            
        </Container>

        </Section>
        
        </>
        
    )
}



